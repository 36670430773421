import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})

export class servicesUrl {
   // public url = 'https://localhost:44325';
   // public url = 'https://api2.wcrg.org:444';
  //public url = 'https://api2.werig.org:444';
  // public url = 'https://api2.firstsourceco.com:444';  
  // public url = ' https://api2.digitalcitye.com:444';  
  //  public url = 'https://api2.yg-u.net:444';
  //  public url = 'https://api2.technomed-ye.com:444';
  // public url = 'https://api2.alshamelah-y0e.com:444';
  // public url = 'https://api2.yuniv.net:444';
  public url = 'https://api2.spectragp.com:444';

}
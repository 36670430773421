import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BranchesService } from 'src/app/services/branches/branches.service';
import { CryptService } from 'src/app/services/crypt.service';
import { users } from 'src/app/services/users/users';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  canvasRotation
  imageChangedEvent
  showImage: boolean = true
  modalRef
  disableSelect
  companyType
  selectedcompanyType
  selectedLevel
  selectedMain
  Main
  selected
  Status
  Level
  showUpdate
  NewsDetails
  imgUrl
  selectedLang: string
  languages: any
  loadPage = true
  rowsPage: number
  tableNews
  selectedRows
  showTableNews: boolean = false
  UserEmail: string = ''
  public Editor = ClassicEditor;
  showSave: boolean = false
  showcancel: boolean = false
  showNew: boolean = true
  showDataPanle
  UserId
  UsrName
  UserPass
  UserPass2
  UserFullName
  UsrMobil
  BrnPho2
  BrnEmail
  BrnFax
  disableSelectLang
  disableSelectType
  disableSelectLevel


  fbrn_sys = 'Faculty'

  save() {
  
    if (this.Validation()) {
      if(this.UserPass == this.UserPass2 )
      {
        this.loadPage = false
        this.showSave = false
      this.users.UsersPost(
        'i', this.UserId, this.UsrName, this.UserPass, this.UserFullName, this.UserEmail,
        this.UsrMobil, this.selectedLang,this.c.Decrypt(sessionStorage.getItem("usrId")),this.selected,this.croppedImage
      )
        .subscribe(data => {
          this.getDataNews()
          this.clearData()
          this.btnclose()
          this._snackBar.open(data[0]["column1"], 'حسنا', {
            duration: 5000,
            horizontalPosition: 'left'
          });
          this.loadPage = true
        })

      }
      else if(this.UserPass != this.UserPass2)
      this._snackBar.open(
       'كلمة المرور غير متطابقة',
       'حسنا', {
        duration: 5000,
        horizontalPosition: 'left'
      });
      else if(this.UserPass == '' || this.UserPass == null || this.UserPass2 == '' || this.UserPass2 == null  )
      this._snackBar.open(
        'الرجاء ادخال كلمة المرور اولا',
        'حسنا', {
         duration: 5000,
         horizontalPosition: 'left'
       });
    }
    else {
      this.showSave = true
      this.loadPage = true
    }
  }


  btnNew() {
    this.disableSelect = null
    this.showSave = true
    this.showcancel = true
    this.showNew = false
    this.showDataPanle = true
    this.showUpdate = false
    this.showTableNews = true
    this.clearData();
  }
  btnclose() {
    this.showSave = false
    this.showcancel = false
    this.showNew = true
    this.showDataPanle = false
    this.showUpdate = false
    this.clearData();
    this.showTableNews = false
  }
  btnUpdate() {
    this.showDataPanle = true
    this.showSave = false
    this.showcancel = true
    this.showUpdate = true;

    this.showNew = false
    this.showTableNews = true
  }

  getDataNews(): void {
    this.getRows('1')
    this.loadPage = true
  }
  clearData(): void {
    this.UserId = ''
    this.id_delete = ''
    this.id_lang = ''
    this.selectedMain = ''
    this.selectedcompanyType = ''
    this.selectedLevel = ''
    this.selectedLang = ''
    this.BrnEmail = ''
    this.BrnFax = ''
    this.UsrMobil = ''
    this.BrnPho2 = ''
    this.UsrName = ''
    this.UserPass = ''
    this.UserFullName = ''
    this.UserEmail = ''
    this.imageChangedEvent = '';
    this.imgUrl = ''
  }

  showData(id): void {
    this.loadPage = false
    this.disableSelect = null
    this.users.getUsersWhereId(id).subscribe(data => {
      console.log(data)
      this.UserId = data[0]['UsrId']
      this.UsrName = data[0]['UsrName']
      // this.UserPass = data[0]['UserPass'].toString()
      this.UserFullName = data[0]['UsrFullName']
      this.UserEmail = data[0]['UsrEmail']
      this.UsrMobil = data[0]['UsrMobile']
      this.selectedLang = data[0]['FbrnId']
      this.selected = data[0]['UsrState']
      // this.croppedImage = data[0]["base64"]
      this.loadPage = true
    });
    this.btnUpdate()
  }


    update() {
    this.loadPage = false
    this.showSave = false
    if (this.Validation()) {
      this.users.UsersPost(
        'u', this.UserId, this.UsrName, this.UserPass, this.UserFullName, this.UserEmail,

        this.UsrMobil, this.selectedLang,this.c.Decrypt(sessionStorage.getItem("usrId")),this.selected,this.croppedImage
      )
        .subscribe(data => {
          this.getDataNews()
          this.clearData()
          this.btnclose()

          this._snackBar.open(data[0]["column1"], 'حسنا', {
            duration: 5000,
            horizontalPosition: 'left'
          });
          this.loadPage = true
        })
    }
    else {
      this.showSave = true
      this.loadPage = true
    }
  }


  toggle(): void {

    this.showSave = !this.showcancel
  }

  constructor(private c: CryptService, private _Activatedroute: ActivatedRoute, private brn: BranchesService,private users:users, private _snackBar: MatSnackBar, private modalService: BsModalService) { }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }



  id_delete: string
  id_lang: string
  message
  delete(template: TemplateRef<any>, id, langId): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.id_delete = id
    this.id_lang = langId
  }


  confirm(): void {
    this.loadPage = false
    this.users.UsersDelete('d', this.id_delete).subscribe(data => {
      this.getDataNews()
      this.clearData()

      this._snackBar.open(data[0]["column1"], 'حسنا', {
        duration: 5000,
        horizontalPosition: 'left'
      });
      this.loadPage = true
    });
    this.id_delete = ''
    this.id_lang = ''
    this.modalRef.hide();
  }
  croppedImage: any = '';
  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }



  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showImage = false
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

  }


  cut() {
    this.showImage = true
  }

  imageLoaded() {
  }
  cropperReady() {
  }

  loadImageFailed() {
    //console.error('Failed to load image: ');
  }



  Validation(): boolean {

    if (this.selectedLang == '' || this.selectedLang == null) {
      this._snackBar.open('الرجاء اختيار الفرع اولا', 'حسنا', {
        duration: 5000,
        horizontalPosition: 'left'
      });
      return false;
    }
    // else if (this.croppedImage == '' || this.croppedImage == null) {

    //   this._snackBar.open('الرجاء اختيار صورة اولا', 'حسنا', {
    //     duration: 5000,
    //     horizontalPosition: 'left'
    //   });
    //   return false;
    // }
    else if (this.UsrName == '' || this.UsrName == null) {

      this._snackBar.open('الرجاء ادخال اسم المستخدم اولا', 'حسنا', {
        duration: 5000,
        horizontalPosition: 'left'
      });
      return false;
    }
    else if (this.UserPass == '' || this.UserPass == null) {

      this._snackBar.open('الرجاء ادخال كلمة المرور اولا', 'حسنا', {
        duration: 5000,
        horizontalPosition: 'left'
      });
      return false;
    }
    else if (this.UserPass2 == '' || this.UserPass2 == null) {

      this._snackBar.open('الرجاء ادخال تاكيد كلمة المرور اولا', 'حسنا', {
        duration: 5000,
        horizontalPosition: 'left'
      });
      return false;
    }

    else if (this.UserFullName == '' || this.UserFullName == null) {

      this._snackBar.open('الرجاء ادخال الاسم كامل اولا', 'حسنا', {
        duration: 5000,
        horizontalPosition: 'left'
      });
      return false;
    }
    else if (this.selected == null) {

      this._snackBar.open('الرجاء اختيار الحالة اولا', 'حسنا', {
        duration: 5000,
        horizontalPosition: 'left'
      });
      return false;
    }

    return true;
  }


  onChangeComType() {
    setTimeout(() => {
      if (this.selectedcompanyType == null || this.selectedcompanyType == '') {
        console.log('Lang Null ...')
      }
      else {
        this.brn.getLevel(this.selectedcompanyType).subscribe(data => {
          this.Level = data
        });
      }
    }, 100);

  }

  onChangeLevel() {
    setTimeout(() => {
      if (this.selectedLevel == null || this.selectedLevel == '') {
        console.log('Lang Null ...')
      }
      else {
        this.brn.getMain(this.selectedLevel, this.fbrn_sys, '1', this.selectedLang).subscribe(data => {
          this.Main = data
          if (data.length == 0) {
            this.disableSelect = new FormControl(false)
            this.selectedMain = '0'
          }
          else {
            this.disableSelect = false
          }
        });
      }
    }, 100);
  }
  pageNumbers

  getRows(num) {
  
    if (this.rowsPage == null)
      this.rowsPage = 10
      this.users.viewUsersPaging(num, this.rowsPage,this.c.Decrypt(sessionStorage.getItem('syslang')),this.c.Decrypt(sessionStorage.getItem('usrId'))).subscribe(data => {
        console.log(data)
      this.tableNews = data['data1']
      this.pageNumbers = data['data2']
    })
  }
  getPage(event: any) {

    this.rowsPage = event.target.value;
    this.users.viewUsersPaging(1, this.rowsPage,this.c.Decrypt(sessionStorage.getItem('syslang')),this.c.Decrypt(sessionStorage.getItem('usrId'))).subscribe(data => {
      console.log(data)
      this.tableNews = data['data1']
      this.pageNumbers = data['data2']
     
    })
  }

  public ff(){
     this.brn.getPageName(this._Activatedroute.snapshot.paramMap.get("id"),this.c.Decrypt(sessionStorage.getItem('syslang'))).subscribe(data=>{
     // this.namebrn = data[0]['PageTitle']
      data.forEach(x=>{
        if(x.FlangId == this.c.Decrypt(sessionStorage.getItem('syslang')))
        {
          this.namebrn = x.PageTitle
        }
      })
    })
  }
  namebrn: string = ''
  ngOnInit(): void {
    this.ff()
  
    this.loadPage = false

    this.users.getUsersStatus(this.c.Decrypt(sessionStorage.getItem('syslang'))).subscribe(data => {

      this.Status = data;
      console.log(data)
    });

    this.users.GetUsersbrnName(this.c.Decrypt(sessionStorage.getItem('syslang'))).subscribe(data => {
      this.languages = data
      console.log(data)
    });
    this.brn.getCompanyType().subscribe(data => {
      this.companyType = data
    });

    this.getDataNews()
    this.loadPage = true


    this.brn.getMain('a', this.fbrn_sys, '1', 'ar').subscribe(data => {
      this.Main = data
      console.log(data)
      if (data.length == 0) {
        this.disableSelect = new FormControl(false)
        this.selectedMain = '0'
      }
      else {
        this.disableSelect = false
      }
    });

  }}